export function hideHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.add('-translate-y-full');
}

export function showHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.remove('-translate-y-full');
}


export function hideBackToTop(){
    let backTop = document.getElementsByClassName( 'back_to_top' );
    backTop[0].classList.add('translate-x-28');

}

export function showBackToTop(){
    let backTop = document.getElementsByClassName( 'back_to_top' );
    backTop[0].classList.remove('translate-x-28');
}

export function swapClasses(ele, class1, class2){
    ele.classList.toggle( class1 );
    ele.classList.toggle( class2 );
}

export function shrinkLogo(){
    let logo = document.getElementsByClassName( 'logo-link' );

    if(logo[0].classList.contains('lg:w-56')){
        console.log('shrinkLogo');
        logo[0].classList.remove('lg:w-56');
    }
}

export function growLogo(){
    let logo = document.getElementsByClassName( 'logo-link' );

    if(!logo[0].classList.contains('lg:w-56')){
         console.log('growLogo');
        logo[0].classList.add('lg:w-56');
    }
}
